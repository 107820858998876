import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dome: 0
  },
  mutations: {
    changedome(state) {
      // 变更状态
      if (state.dome == 0)
        state.dome = 1;
      else
        state.dome = 0;
    }
  },
  actions: {
  },
  modules: {
  }
})
