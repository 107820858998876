import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: () => import('../views/Index.vue')
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/',
        component: () => import('../views/Index.vue'),
      },
      {
        path: 'about',
        component: () => import('../views/manage/About.vue'),
      },
      {
        path: 'upload',
        component: () => import('../views/manage/Upload.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
